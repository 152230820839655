import { useState, useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import depositABI from './v2/periphery.json'
import depositZyxABI from './v2/mainHub.json'

export const useRedeem = () => {
  const { account, library } = useWeb3React()
  const [txResult, setTxResult] = useState({})

  const handleRedeem = useCallback(
    async (net, res) => {
      try {
        const {
          token,
          user,
          amount,
          fee,
          hash,
          deadline,
          signature,
          chain_id_to,
        } = res


        const fromZyx = net.name.includes('Zyx')
        const ABI = fromZyx ? depositZyxABI : depositABI
        const contract = new Contract(net.contract, ABI, library.getSigner())
        // const tokenAddr = net.tokens[token].address

        const call = () => contract.redeemToken(token, user, amount, fee, chain_id_to, hash, deadline, signature)
        const callZyx = () => contract.redeem(token, {value: '0'})

        const promise = fromZyx ? callZyx() : call()
        promise
          .then(tx => {
            tx.wait(1).then(receipt => {
              setTxResult(receipt)
            })
          })
          .catch(e => {
            console.log(e)
            setTxResult('error')
          })
      } catch (e) {
        console.log(e)
        setTxResult('error')
        return e
      }
    },
    [library, account]
  )

  return { onRedeem: handleRedeem, result: txResult }
}
