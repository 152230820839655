import { NavLink } from 'react-router-dom'
import WalletButton from './wallet-btn'

import Logo from '../image/logo-white.png'

export default () =>
        <header>
            {/*<div style={{textAlign: 'center', background: 'rgb(227 70 42)'}}>This project is in beta. Use at your own risk.</div>*/}
            <div className='container'>
                <div className='top-header'>
                  <div className='logo-wrap'>
                    <NavLink to={'/'}>
                      <img style={{maxHeight: '60px'}} src={Logo} alt=''/>
                    </NavLink>
                    <p className='beta'>beta</p>
                  </div>
                    <div className='navbar'>
                        <ul className='navbar-list'>
                            <NavListLink exact to={'https://doc.zyx.network/zyx-docs/bridge-pandorum.io'}>Docs</NavListLink>
                          {/*<NavListLink exact to={'/'}>Bridge</NavListLink>*/}
                          {/*<NavListLink to={'/stats'}>Stats</NavListLink>*/}
                            {/*<NavListLink to={'/stake'}>Staking</NavListLink>*/}
                            {/*<NavListLink to={'/faq'}>FAQ</NavListLink>*/}
                        </ul>
                        <WalletButton/>
                    </div>
                </div>
            </div>
        </header>

const NavListLink = ({to, children, exact}) =>
    <li className='navbar-list-item'>
        { to.includes('https') ?
            <a href={to} target={'_blank'} className={'navbar-list-item-link'}>{children}</a>
        :
            <NavLink exact={!!exact} to={to} activeStyle={linkActiveStyle} className='navbar-list-item-link'>{children}</NavLink>
        }
    </li>

const linkActiveStyle = {
    background: '#21222d',
    color: '#fff'
}
