import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { decimals, Networks, Tokens } from '../constants'
import {Contract} from "@ethersproject/contracts";
import {formatUnits} from "@ethersproject/units";
import { fetcher } from './fetcher'
import useSWR from 'swr'
import depositABI from './v2/periphery.json'
import depositZyxABI from './v2/mainHub.json'

export const useMinSwap = (tokenStr, targetChainId) => {
  const { library, chainId } = useWeb3React()
  const [minswap, setMinswap] = useState('0')

  useEffect(() => {
    if (library) {
      const net = Object.values(Networks).find(n => n.ChainId === chainId)
      if (net !== undefined) {
        const token = net.tokens[tokenStr]
        if (token !== undefined) {
          const decimals = token?.decimals ? token.decimals : Tokens[tokenStr].decimals

          const tokenAddr = token.address
          const fromZyx = net.name.includes('Zyx')
          const ABI = fromZyx ? depositZyxABI : depositABI
          const contract = new Contract(net.contract, ABI, library.getSigner())
          const call = () => contract.tokenInfo(tokenAddr)
          const zyxCall = () => contract.tokenInfo(targetChainId, tokenAddr)

          const promise = fromZyx ? zyxCall() : call()
          promise.then(d => setMinswap(formatUnits(d.minLimit, decimals)))
        }
      }
    }
  }, [tokenStr, chainId, targetChainId])

  return minswap
}
