import Sprite from './../image/sprite.svg'

export default () =>
    <footer className={'footer'}>
        <div className={'container'}>
            <div className={'footer-flex'}>
                <Icon href='https://twitter.com/pandorumbridge' name='social-media (5)'/>
                <Icon href='https://discord.com/invite/h3GeZKH2DQ' name='discord'/>
            </div>
        </div>
    </footer>

const Icon = ({href, name}) => {
    return (
      <a target='_blank' href={href} style={{margin: '0 .5em'}}>
        <svg width='48' fill='white'>
          <use href = {Sprite + `#${name}`} />
        </svg>
      </a>
    )
}