import './css/main.css'
import './css/mobile.css'
import './css/media.css'
import './fonts/gilroy/gilroy-font.css'
import './fonts/Roboto.css'

import { BrowserRouter as Router} from 'react-router-dom'
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"

import Header from './components/header'
import Page from './components/page'
import Footer from './components/footer'

const getLibrary = provider => {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

function App() {
    return (
        <div className="App">
            <Web3ReactProvider getLibrary={getLibrary} libraryName={'web3.js'}>
                <Router>
                    <Header/>
                    <Page/>
                    <Footer/>
                </Router>
            </Web3ReactProvider>
        </div>
    );
}

export default App;
