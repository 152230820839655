import { Redirect, Route, Switch } from 'react-router-dom'
import SwapPage from "./swap/page";
import StatsPage from "./stats/page";
import WalletOverlay from "./wallet-overlay";

export default () => {
    return (
        <Switch>
          <Route exact path={'/'}>
            <WalletOverlay>
              <SwapPage/>
            </WalletOverlay>
          </Route>
          <Route path={'/stats'}>
            <WalletOverlay>
              <StatsPage/>
            </WalletOverlay>
          </Route>
          <Route render={() => <Redirect to={'/'}/>}/>
          {/*<Route path={'/stake'}>*/}
          {/*  <StakingDashboard/>*/}
          {/*</Route>*/}
        </Switch>
    )
}

