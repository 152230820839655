import { InjectedConnector } from "@web3-react/injected-connector";

import zyxIcon from './image/swap-icon.png'
import ethZyxIcon from './image/swap-icon3.png'
import ethIcon from './image/eth.png'
import usdtIcon from './image/usdt.png'
import usdcIcon from './image/usdc.png'
import daiIcon from './image/dai.png'
import ustIcon from './image/ust.png'
import bscZyxIcon from './image/swap-icon4.png'

export const ZYX_NODE = 'https://info.pandorum.io'

const EthMain = 1
const Kovan = 42
export const Ropsten  = 3
const BSC = 56
export const ZyxSwap = 18
const BSC_Test = 0x61

export const Connector = new InjectedConnector({
})

export const Tokens = {
    weth: { name: 'WETH', icon: ethIcon, decimals: 18 },
    wzyx: { name: 'WZYX', icon: ethZyxIcon, decimals: 18 },
    usdc: { name: 'USDC', icon: usdcIcon, decimals: 18 },
    usdt: { name: 'USDT', icon: usdtIcon, decimals: 18 },
    wbnb: { name: 'WBNB', icon: bscZyxIcon, decimals: 18 },
    dai: { name: 'DAI', icon: daiIcon, decimals: 18 },
    ust: { name: 'UST', icon: ustIcon, decimals: 18 },
}

export const Networks = {
    Eth: {
        ChainId: 1,
        name: 'Ethereum',
        color: '#627eea',
        contract: '0xF823caFaf38593F7ba23CD1503D9203603FE1247',
        tokens: {
            usdt: { address: '0xdac17f958d2ee523a2206206994597c13d831ec7', isPercent: false, fee: '1', decimals: 6},
            usdc: { address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', isPercent: false, fee: '1', decimals: 6},
            wzyx: { address: '0xdc540d5d2b6ca8fe0cfe8e975c944e0b4a9dab87', isPercent: true, fee: '4'},
            dai: { address: '0x6b175474e89094c44da98b954eedeac495271d0f', isPercent: false, fee: '50'}
        }
    },
    // Ropsten: {
    //     ChainId: 3,
    //     contract: '0x3316D2edFe8b29106083fd5D7233Be0BdDA19a03',
    //     name: 'Ropsten',
    //     color: '#627eea',
    //     tokens: {
    //         weth: { fee: '0.00005', isPercent: false, address: "0xc778417E063141139Fce010982780140Aa0cD5Ab"},
    //         wzyx: { fee: '0.00005', isPercent: false, address: '0x5ed7925D8975d1F3E0A4E24569558B9BF061DfE9'},
    //         usdt: { fee: '1', isPercent: false, address: '0xFABC047A4F2aB3b647dC0e0E685C50393a8931db'},
    //     }
    // },
    Bsc: {
        ChainId: 56,
        rpc: ['https://bsc-dataseed.binance.org/'],
        explorer: 'https://www.bscscan.com/',
        name: 'BSC',
        contract: '0xF823caFaf38593F7ba23CD1503D9203603FE1247',
        nativeCurrency: 'BNB',
        color: '#f3ba2f',
        tokens: {
            wzyx: { address: '0x377c6e37633e390aef9afb4f5e0b16689351eed4', isPercent: true, fee: '4'},
            usdt: { address: '0x55d398326f99059fF775485246999027B3197955', isPercent: false, fee: '1' },
            usdc: { address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', isPercent: false, fee: '1' },
            dai: { address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', isPercent: false, fee: '0'}
        }

    },
    Zyx: {
        ChainId: 55,
        name: 'Zyx Mainnet',
        rpc: ['https://rpc-1.zyx.network/','https://rpc-2.zyx.network/','https://rpc-3.zyx.network/','https://rpc-4.zyx.network/'],
        explorer:'https://zyxscan.com/',
        nativeCurrency: 'ZYX',
        color: '#bb6bd9',
        contract: '0xF823caFaf38593F7ba23CD1503D9203603FE1247',
        tokens: {
            wzyx: { alias: 'ZYX', fee: '0.1', isPercent: false, address: '0x0000000000000000000000000000000000000000' },
            usdt: { alias: 'zUSDT', address: '0xefcAA73145B5e29eEfc47bcbaeFF9e870Fa6a610', isPercent: false, fee: '1'},
            usdc: { alias: 'zUSDC', address: '0xb99c32A2DA6766158b4ccF29B26E75DC22606eBD', isPercent: false, fee: '1'},
            dai: { address: '0x3d9b64924e9d7bb88b6f5a5460c470b73c5ed1de', isPercent: false, fee: '1' }
        }
    },
    Arbitrum: {
        ChainId: 42161,
        contract: '0xF823caFaf38593F7ba23CD1503D9203603FE1247',
        rpc: ['https://arbitrum-mainnet.infura.io/v3/a96e8c3be56d43659187f7b5259a2e16'],
        explorer: 'https://explorer.arbitrum.io/',
        nativeCurrency: 'ETH',
        name: 'Arbitrum',
        color: '#28a0f0',
        tokens: {
            wzyx: { address: '0x377c6e37633e390aef9afb4f5e0b16689351eed4', isPercent: true, fee: '4'},
            usdt: { decimals: 6, address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', isPercent: false, fee: '1' },
            usdc: { decimals: 6, address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', isPercent: false, fee: '1' },
            dai: { address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1', isPercent: false, fee: '50'}
        }
    },
    // Zyx: {
    //     ChainId: 18,
    //     rpc: 'https://api-minerva.zyx.network',
    //     contract: '0x1e108A8eC803cb17D6B8f2a238433762E3e7508b',
    //     name: 'Zyx Testnet',
    //     color: '#bb6bd9',
    //     tokens: {
    //         wzyx: { fee: '0.00005', isPercent: false, address: '0x0000000000000000000000000000000000000000' },
    //         weth: { fee: '0.00005', isPercent: false, address: '0xCB1C22BCE13A02f27ef2d942D6bae642197Edb29' },
    //         // usdt: { fee: '1', isPercent: false, address: '0x5e32d31eBdcdcF9dc11cB49b69fD722691963e87' },
    //     }
    // }
}

export const Farms = [
    {
        pair: "ETH",
        contractAddr: "0xB529D41aFbc67A1B8E9BD78928DddAF6abf569E4",
        chainId: EthMain,
        swappingPlatform: 'uniswap'
    },
    {
        pair: "BNB",
        contractAddr: "",
        chainId: BSC,
        swappingPlatform: 'pancakeswap'
    }
]
