const WalletOverlay = ({children}) => {
    return (
      <>
          <div style={center}>
              {children}
          </div>
      </>
    )
}

const center = {
    display: 'flex',
    justifyContent: 'center'
}

export default WalletOverlay