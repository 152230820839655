import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { decimals, Networks, Tokens, ZYX_NODE } from '../constants'
import {Contract} from "@ethersproject/contracts";
import {formatUnits} from "@ethersproject/units";
import { fetcher } from './fetcher'
import useSWR from 'swr'
import depositABI from './depositABI.json'
import depositZyxABI from './depositZyxABI.json'
import { parseUnits } from 'ethers/lib/utils'
import axios from 'axios'

export const usePoolLocks = () => {
  const [locks, setLocks] = useState({})

  useEffect(() => {
    axios.get(ZYX_NODE + '/reserves')
      .then(r => {
        if (r.status === 200) {
          const data = r.data.reduce((result, v) => {
            const chainName = Object.keys(Networks).find(k => Networks[k].ChainId === parseInt(v.chain_id))
            const tokenName = Object.keys(Networks[chainName].tokens).find(k => Networks[chainName].tokens[k].address.toLowerCase() === v.token.toLowerCase())
            console.log(chainName, tokenName, v.balance)
            if(chainName && tokenName) {
              const tokenDecimals = Networks[chainName].tokens[tokenName].decimals ?? Tokens[tokenName].decimals
              result[chainName] = result[chainName] ?? {}
              result[chainName][tokenName] = formatUnits(v.balance, tokenDecimals)
            }
            return result
          }, {})
          setLocks(data)
        }
      })
      .catch(console.error)
  }, [])

  return locks
}
