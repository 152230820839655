import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { decimals, Networks, Tokens, ZYX_NODE } from '../constants'
import {Contract} from "@ethersproject/contracts";
import {formatUnits} from "@ethersproject/units";
import { fetcher } from './fetcher'
import useSWR from 'swr'
import depositABI from './depositABI.json'
import depositZyxABI from './depositZyxABI.json'
import { parseUnits } from 'ethers/lib/utils'
import axios from 'axios'

export const useTVL = () => {
  const [tvl, setTvl] = useState('0')

  useEffect(() => {
      axios.get(ZYX_NODE + '/tvl')
        .then(r => {
          if (r.status === 200) {
            setTvl(r.data)
          }
        })
        .catch(console.error)
  }, [])

  return tvl
}
