import { Networks, Tokens } from '../../constants'
import { ethers } from 'ethers'
import { usePoolLocks } from '../../hooks/usePoolLocks'

export const LockTable = () => {
  const nets = Object.entries(Networks)
  const locks = usePoolLocks()

  const displaySetting = {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }

  return (
    <div className='from-block net-container'>
      <p className='from__title'>Net Liquidity</p>
      {
        nets.map(([netStr, net]) =>
        !net.name.includes('Zyx') &&
        <div className='net-container-net'>
          <span style={{color: net.color}}>{net.name}</span>
          {
             Object.entries(net.tokens).map(([name, token])=>
               name !== 'wzyx' && <div className='net-container-token'>
                 <div style={{display: 'flex', flexDirection: 'row'}}>
                   <img style={{marginRight: '.3em'}} width={24} src={getTokenIcon(net, name)} alt={name}/>
                   <p>{getTokenName(net, name)}</p>
                 </div>
                 <p className='currency'>{parseFloat(locks[netStr]?.[name] ?? '0').toLocaleString('en-GB', displaySetting)}</p>
               </div>)
          }
        </div>)
      }
    </div>
  )
}

const getTokenName = (net, tokenStr) => {
  return net.tokens[tokenStr].alias ?? Tokens[tokenStr].name
}

const getTokenIcon = (net, tokenStr) => {
  return Tokens[tokenStr].icon
}