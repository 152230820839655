import { useState, useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { parseUnits } from 'ethers/lib/utils'
import depositABI from './depositABI.json'
import depositZyxABI from './depositZyxABI.json'
import axios from 'axios'
import { Tokens, ZYX_NODE } from '../constants'

export const useDeposit = (recover) => {
  const { account, library } = useWeb3React()
  const [txResult, setTxResult] = useState({})

  useEffect(() => {
    if (recover.recover) {
      setTxResult({status: 1, ...recover})
    }
  }, [recover])

  const handleBurn = useCallback(
    async (fromNet, toNet, tokenStr, amount) => {
      if (recover.recover) {
        return
      }
      try {
        const token = fromNet.tokens[tokenStr]
        const decimals = token.decimals ? token.decimals : Tokens[tokenStr].decimals
        const fromZyx = fromNet.name.includes('Zyx')
        const ABI = fromZyx ? depositZyxABI : depositABI
        const options = fromZyx && tokenStr === 'wzyx' ? { value: parseUnits(amount.toString(), decimals) } : {}
        const contract = new Contract(fromNet.contract, ABI, library.getSigner())
        const tokenAddr = fromNet.tokens[tokenStr].address

        const call = () => contract.depositToken(tokenAddr, parseUnits(amount.toString(), decimals))
        const callZyx = () => contract.deposit(tokenAddr, parseUnits(amount.toString(), decimals), toNet.ChainId, options)

        const promise = fromZyx ? callZyx() : call()
        promise
          .then(tx => {
            tx.wait(1).then(receipt => {
              setTxResult({chainId: fromNet.ChainId, ...receipt, status: null})
            })
          })
          .catch(e => {
            console.log(e)
            setTxResult('error')
          })
      } catch (e) {
        console.log(e)
        setTxResult('error')
      }
    },
    [library, account]
  )

  useEffect(() => {
    if (txResult.status !== undefined || txResult.recover) {
      const checkInterval = setInterval(() => {
        axios.get(ZYX_NODE + '/info', { params: { hash: txResult.transactionHash }})
          .then(res => {
            if (res.data) {
              console.log(res.data)
              setTxResult({...res.data, transactionHash: txResult.transactionHash})
              clearInterval(checkInterval)
            }
          }).catch(console.error)
      }, 5000)

      return () => clearInterval(checkInterval)
    }
  }, [txResult])

  return { onDeposit: handleBurn, result: txResult }
}
