import ButtonIcon from '../image/wallet.png'
import {useWeb3React} from "@web3-react/core";
import { Connector } from "../constants";

export default ({style}) => {
    const { account, activate } = useWeb3React()

    return (
        <button style={style ? style : null} className={'connect-wallet'}
                onClick={() => { if (!account){ activate(Connector)} }}>
            <img src={ButtonIcon} alt={""}/>
            { account ?
                <>{truncated(account, 13)}</>
                :
                <>Connect Wallet</>
            }
        </button>
    )
}

const truncated = (text, len) =>
    text + 3 > len ?
        `${text.substring(0, len / 2)}...${text.substring(text.length - len / 2 + 3, text.length)}`
    : text