import { useState } from 'react'
import { Networks } from '../../constants'
import ReactModal from 'react-modal'
import { useTable } from 'react-table'
import { useTVL } from '../../hooks/useTVL'

export default () => {
  const [net, setNet] = useState(Object.values(Networks)[0])
  const [modalOpen, setModalOpen] = useState(false)

  const tvl = useTVL()

  const data = [
    { token: 'usdt', value: '100' },
    { token: 'wzyx', value: '100' },
  ]

  const columns = [
    { Header: 'Token', accessor: 'token' },
    { Header: 'Value', accessor: 'value' },
  ]

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable({columns, data})

  return (
    <section className='swap'>
      <ReactModal ariaHideApp={false}
                  onRequestClose={() => { setModalOpen(false) }}
                  preventScroll={true}
                  style={modalStyle}
                  isOpen={modalOpen}
      >
        <NetworkSelectModal selector={net => { setNet(net); setModalOpen(false)}}/>
      </ReactModal>
      <div className='container' style={{minWidth: '400px'}}>
        <div className='from__title'>
          <div>Stats for <a onClick={e => { e.preventDefault(); setModalOpen(true) }}><span style={{color: net.color, cursor: 'pointer'}}>{net.name}</span></a></div>
        </div>
          {/*<table {...getTableProps()}>*/}
          {/*  <thead>*/}
          {/*  {headerGroups.map(h => <tr {...h.getHeaderGroupProps()}>*/}
          {/*    {h.headers.map(column => <th {...column.getHeaderProps()}>{column.render("Header")}</th>)}*/}
          {/*  </tr>)}*/}
          {/*  </thead>*/}
          {/*  <tbody {...getTableBodyProps()}>*/}
          {/*  {rows.map((row, i) => {*/}
          {/*    prepareRow(row);*/}
          {/*    return (*/}
          {/*      <tr {...row.getRowProps()}>*/}
          {/*        {row.cells.map(cell => {*/}
          {/*          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;*/}
          {/*        })}*/}
          {/*      </tr>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*  </tbody>*/}
          {/*</table>*/}
      </div>
    </section>
  )
}

const NetworkSelectModal = ({ selector }) => {
  return (
    <div className="modal-content">
      <div className="modal-title">
        <p className="modal__title">Select network</p>
      </div>
      <div>
        {
          Object.entries(Networks).map(([_, v]) =>
            <button style={{ width: '100%', marginBottom: '5px' }} onClick={() => {selector(v)}}
                    className="btn button">
              <p>{v.name}</p>
            </button>
          )
        }
      </div>
    </div>
  )
}

const modalStyle = {
  content: {
    zIndex: 999,
    background: '#242628',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    borderColor: '#4a4a4a',
    minWidth: '220px',
    padding: '30px',
    userSelect: 'none',
    boxShadow: '0px 10px 13px -7px #151c23'
  },
  overlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
}
