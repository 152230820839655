import SwitchIcon from '../../image/swap-icon2.png'
import { useEffect, useReducer, useState } from 'react'
import { Networks, Tokens, Connector } from '../../constants'
import ReactModal from 'react-modal'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useBalance } from '../../hooks/useBalance'
import Steps from 'rc-steps'
import '../../css/steps.css'
import '../../css/spinner.css'
import { useDeposit } from '../../hooks/useDeposit'
import { useRedeem } from '../../hooks/useRedeem'
import { useMinSwap } from '../../hooks/useMinSwap'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useAllowance } from '../../hooks/useAllowance'
import { useTOS } from '../../hooks/useTOS'
import { useRecover } from '../../hooks/useRecover'
import { NetworkSelector } from '../network-selector'
import { useTVL } from '../../hooks/useTVL'
import { LockTable } from './lock-table'
import {usePoolLocks} from "../../hooks/usePoolLocks";

export default () => {
  const { chainId, library, account, active, activate } = useWeb3React()
  const [fromSum, setFromSum] = useState()
  const [toSum, setToSum] = useState()

  const [fromNet, setFromNet] = useState(Object.values(Networks)[0])
  const [toNet, setToNet] = useState(Networks.Zyx)
  const [token, setToken] = useState(getCommonTokens(Object.values(Networks)[0], Networks.Zyx)[0])
  const tokenDecimals = fromNet.tokens[token]?.decimals ? fromNet.tokens[token].decimals : Tokens[token].decimals

  const [modalOpen, setModalOpen] = useState(false)
  const [netSelectMode, setNetSelectMode] = useState('from')

  const [modalType, setModalType] = useState('token')
  const minSwap = useMinSwap(token, toNet.ChainId)
  const balance = useBalance(fromNet, token)
  const { allowance, onApprove, result: approveResult } = useAllowance(fromNet, token)

  const [isSwap, setSwap] = useState(true)

  const { onSign, signed } = useTOS()

  const { onRecover, recoverData } = useRecover()
  const [txHash, setTxHash] = useState('')

  const tvl = useTVL()
  const reserves = usePoolLocks()

  const openNetModal = () => {
    setModalType('net')
    setModalOpen(true)
  }

  const fromInputChanged = val => {
    const fee = toNet.tokens[token].fee
    const isPercent = toNet.tokens[token].isPercent
    if (val === '') {
      setFromSum('')
      setToSum('')
    }
    try {
      const zero = parseUnits("0", tokenDecimals)
      const valBN = parseUnits(val, tokenDecimals)
      const balBN = parseUnits(balance, tokenDecimals)
      const a = balBN.lt(valBN) ? balBN : valBN
      const withFee = calculateFee(a, fee, isPercent, tokenDecimals, true)
      const r = formatUnits(withFee.gt(zero) ? withFee : zero, tokenDecimals)
      setFromSum(balBN.lte(valBN) ? balance : val)
      setToSum(r)
    } catch {
    }
  }

  const toInputChanged = val => {
    const fee = toNet.tokens[token].fee
    const isPercent = toNet.tokens[token].isPercent
    if (val === '') {
      setFromSum('')
      setToSum('')
    }
    try {
      const zero = parseUnits("0")
      const valBN = parseUnits(val, tokenDecimals)
      const balBN = parseUnits(balance, tokenDecimals)
      const maximumTo = calculateFee(balBN, fee, isPercent, tokenDecimals, true)
      const withFee = calculateFee(valBN, fee, isPercent, tokenDecimals, false)
      const r = formatUnits(withFee, Tokens[token].decimals)
      const m = maximumTo.gte(zero) ? maximumTo : zero
      setFromSum(m.lte(valBN) ? balance : r)
      setToSum(m.lte(valBN) ? formatUnits(m, tokenDecimals) : val)
    } catch {
    }
  }

  const confirmPressed = () => {
    openNetModal()
  }

  const openTokenSelector = (mode) => {
    setNetSelectMode(mode)
    setModalType('token')
    setModalOpen(true)
  }

  const openNetworkSelector = (setter) => {
    setNetSelectMode(setter)
    setModalType('network')
    setModalOpen(true)
  }

  const switchNetwork = (target, net) => {
    if (target === 'from') {
      if (net === toNet && toNet === Networks.Zyx) {
        swapNetwork()
      } else if (net !== Networks.Zyx && toNet !== Networks.Zyx) {
        setToNet(Networks.Zyx)
        setFromNet(net)
      } else {
        setFromNet(net)
      }
    } else {
      if (net === fromNet && fromNet === Networks.Zyx) {
        swapNetwork()
      } else if (net !== Networks.Zyx && fromNet !== Networks.Zyx) {
        setFromNet(Networks.Zyx)
        setToNet(net)
      } else {
        setToNet(net)
      }
    }
    if (modalType !== 'net') {
      setModalOpen(false)
    }
  }

  useEffect(() => {
    selectToken(getCommonTokens(fromNet, toNet)[0])
  }, [fromNet, toNet])

  const selectToken = (token) => {
    setToken(token)
    if (modalType !== 'net') {
      setModalOpen(false)
    }
  }

  const approved = () => {
    if (allowance === '-') {
      return true
    } else {
      try {
        return ethers.utils.parseUnits(allowance, tokenDecimals)
          .gte(ethers.utils.parseUnits(fromSum, tokenDecimals))
      } catch {
        return true
      }
    }
  }

  useEffect(() => {
    fromInputChanged(fromSum)
  }, [balance])

  // useEffect(() => {
  //   callConnectorNetworkSwitch(fromNet)
  // }, [fromNet])

  const callConnectorNetworkSwitch = (net) => {
    const chainStr = `0x${net.ChainId.toString(16)}`
    if (library) {
      const eth = new ethers.providers.Web3Provider(library.provider)
      eth.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainStr }]
      }).catch(switchError => {
        if (switchError.code === 4902) {
          eth.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainName: net.name,
              chainId: chainStr,
              rpcUrls: net.rpc,
              blockExplorerUrls: [net.explorer],
              nativeCurrency: {
                name: net.nativeCurrency,
                symbol: net.nativeCurrency,
                decimals: 18
              }
            }]
          }).catch(e => console.error('add error', e))
        }
      })
    }
  }

  useEffect(() => {
    const net = Object.entries(Networks).map(e => e[1]).find(e => e.ChainId === chainId)
    if (net != null) {
      switchNetwork('from', net)
      toInputChanged(fromSum)
    }
  }, [chainId])

  const swapNetwork = () => {
    setFromNet(toNet)
    setToNet(fromNet)
  }

  const swappable = () => {
    try {
      return parseUnits(fromSum, tokenDecimals).gte(parseUnits(minSwap, tokenDecimals))
        && parseUnits(fromSum, tokenDecimals).lte(parseUnits(balance, tokenDecimals))
        && enoughReserves()
    } catch {
      return false
    }
  }

  const enoughReserves = () => {
    if (fromNet.name.includes('Zyx') && token !== 'wzyx') {
      const fromSumDecimals = parseUnits(reserves[toNet.name]?.[token] ?? '0', tokenDecimals)
      // return parseUnits(fromSum !== '' && fromSum !== undefined ? fromSum : '0', tokenDecimals).lte(fromSumDecimals)
      return true
    }
    return true
  }

  const onTxHashChange = (hash) => {
    setTxHash(hash)
  }

  useEffect(() => {
    if (recoverData.recover) {
      openNetModal()
    }
  }, [recoverData])

  return (
    <section className={'swap'}>
      <ReactModal ariaHideApp={false}
                  onRequestClose={() => {
                    if (modalType !== 'net') {
                      setModalOpen(false)
                    }
                  }}
                  preventScroll={true}
                  style={modalStyle}
                  isOpen={modalOpen}
      >
        {modalType === 'net'
          ? <> {signed
            ? <ConfirmationModal amount={fromSum} callNetSwitch={callConnectorNetworkSwitch} token={token}
                                 fromNet={fromNet} toNet={toNet} requestClose={() => setModalOpen(false)}
                                 provider={library.provider}
                                 recover={isSwap ? {} : recoverData}
            />
            : <SignModal onSign={onSign}/>
          }</>
          : null}
        {modalType === 'network'
          ? <NetworkSelectModal selector={net => switchNetwork(netSelectMode, net)}/>
          : null}
        {modalType === 'token'
          ? <TokenModal net={netSelectMode === 'from' ? fromNet : toNet} onClick={selectToken} tokens={getCommonTokens(fromNet, toNet)}/>
          : null}
      </ReactModal>
        <div style={{justifyContent: 'center'}} className='from__title tvl'>
         <div>Total Value Locked: <span style={{color: 'rgb(187, 107, 217)'}}>{ethers.utils.commify(tvl)}</span> USD</div>
         </div>
        <div style={{justifyContent: 'center'}} className='from__title tvl'>
          <div>Currently supported: <span style={{color: 'rgb(187, 107, 217)'}}>{4}</span> Networks</div>
        </div>
      <div className='mode-select'>
          <button className={'mode-button'} style={{fontWeight: isSwap ? 'bolder' : ''}} onClick={() => setSwap(true)}>
            Transfer
          </button>
          <button className={'mode-button'} style={{fontWeight: isSwap ? '' : 'bolder'}} onClick={() => setSwap(false)}>
            Claim
          </button>
        </div>

        { isSwap
        ? <>
            <div className={'from-block'}>
              <div className={'from__title'}>
                <div>From <NetworkSelector onClick={openNetworkSelector}
                                           selector={'from'} net={fromNet}/>
                </div>
                <div class='balance-container' style={{ display: 'flex', marginBottom: '2px', justifyContent: 'space-between' }}>
                  <p className="balance">Balance: {chainId === fromNet.ChainId ? balance : '-'}</p>
                  <button
                    style={{ backgroundColor: chainId === fromNet.ChainId ? `${fromNet.color}43` : '#3a3a3a' }}
                    className="btn max"
                    onClick={() => {
                      if (chainId === fromNet.ChainId) {
                        fromInputChanged(balance)
                      }
                    }}>MAX
                  </button>
                </div>
              </div>
              <div className="from-flex">
                <SumInput value={fromSum}
                          onChanged={e => fromInputChanged(e.target.value)}/>
                <TokenIndicator onClick={() => openTokenSelector('from')} net={fromNet} token={token}/>
              </div>
            </div>
            <div onClick={swapNetwork} className={'swap__image'}>
              <img src={SwitchIcon} alt={''}/>
            </div>

            <div className={'from-block from-block-2'}>
              <div className={'from__title'}>
                <div>To <NetworkSelector onClick={openNetworkSelector} selector={'to'}
                                         net={toNet}/></div>
              </div>
              <div className="from-flex">
                <SumInput value={toSum} onChanged={e => toInputChanged(e.target.value)}/>
                <TokenIndicator onClick={() => openTokenSelector('to')} net={toNet} token={token}/>
              </div>
            </div>
            {active
              ? <>{chainId === fromNet.ChainId
                ? <> {approved()
                  ? <button style={{ width: '100%', marginBottom: '15px' }} onClick={confirmPressed}
                            className={'btn from-button hover'}
                            disabled={!swappable()}
                  >
                    { enoughReserves() ? 'confirm' : 'insufficient liquidity' }
                  </button>
                  : <> {approveResult === 'waiting'
                    ? <Spinner style={{alignSelf: 'center'}}/>
                    : <button style={{ width: '100%', marginBottom: '15px' }}
                              onClick={() => onApprove(fromNet, token)}
                              className={'btn from-button hover'}
                    >
                      approve {token}
                    </button>} </>
                }</>
                : <button style={{ width: '100%', marginBottom: '15px' }}
                          onClick={() => callConnectorNetworkSwitch(fromNet)}
                          className={'btn from-button hover'}
                >
                  connect to {fromNet.name}
                </button>
              }</>
              : <button style={{ width: '100%', marginBottom: '15px' }} onClick={() => activate(Connector)}
                        className={'btn from-button hover'}
              >
                connect wallet
              </button>
            }
            {
              active &&
              <p className={'trwarn'} hidden={swappable()} style={{ width: '100%', textAlign: 'center' }}>Minimum
                transaction value: {minSwap} {token.toUpperCase()}</p>
            }
          </>
        : <>
            <div className={'from-block'}>
              <div className="from-flex">
                <div className="from-input fullwidth">
                  <input onChange={e => onTxHashChange(e.target.value)} value={txHash} type="text" className="from__input" placeholder="Transaction hash"/>
                </div>
              </div>
            </div>
            { active
              ? <button  onClick={() => onRecover(txHash)} className='btn from-button fullwidth' disabled={txHash.length < 10}>Claim</button>
              : <button style={{ width: '100%', marginBottom: '15px' }} onClick={() => activate(Connector)}
                        className={'btn from-button hover'}
              >
                connect wallet
              </button>
            }
          </>}
      <LockTable/>
    </section>
  )
}

const calculateFee = (num, fee, percent, decimals, isFrom) => {
  if (fee === '0') {
    return num
  }
  if (percent) {
    if (isFrom) {
      return num.div(100).mul(100 - fee)
    } else {
      return num.div(100).mul(100 + parseInt(fee))
    }
  } else {
    if (isFrom) {
      return num.sub(parseUnits(fee, decimals))
    } else {
      return num.add(parseUnits(fee, decimals))
    }
  }
}

const getCommonTokens = (netFrom, netTo) => {
  const tokensOne = netFrom.tokens
  const tokensTwo = netTo.tokens
  return Object.keys(tokensOne).map(n => tokensTwo[n] ? n : null).filter(t => t !== null)
}

const SumInput = ({ value, onChanged }) => {
  return (
    <div className="from-input">
      <input onChange={onChanged} value={value} type="text" className="from__input" placeholder="0.00"/>
    </div>
  )
}

const TokenIndicator = ({ net, token, onClick }) => {
  const icon = Tokens[token].icon
  const ticker = net?.tokens[token]?.alias ? net.tokens[token].alias : Tokens[token].name

  return (
    <div className="open-modal from__button" style={{ borderRadius: '15px' }} onClick={onClick}>
      <div className="button__image">
        <img src={icon} alt=""/>
        <p style={{textAlign: 'center', flexGrow: 1, fontFamily: 'Gilroy'}}>{ticker}</p>
      </div>
    </div>
  )
}

const TokenModal = ({ tokens, onClick, net }) => {
  return (
    <div className="modal-content">
      <div className="modal-title">
        <p className="modal__title">Select token</p>
      </div>
      <form action="">
        <div className="result">
          {
            Object.entries(tokens).map(([k, v]) =>
              <div className="open-modal from__button"
                   style={{ marginBottom: '5px' }}
                   onClick={() => onClick(v)}>
                <div className="button__image">
                  <img src={Tokens[v].icon} alt=""/>
                  <b>{net.tokens[v].alias ? net.tokens[v].alias : Tokens[v].name}</b>
                </div>
              </div>
            )
          }
        </div>
      </form>
    </div>
  )
}

const SignModal = ({signed, onSign}) => {
  return (
    <div className="modal-content" style={{ width: '455px', minHeight: '400px' }}>
      <div className="modal-title">
        Terms & Conditions agreement
      </div>
      <div style={{ userSelect: 'text' }} className="result">
        <div className="centered">
          <p>By using Pandorum dApp, I agree to the Terms of Use and Privacy Policy.</p>
          <div className='tos'>
            <p>1) I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, as defined in the Terms of Use. </p>
            <p>2) I will not in the future access this site or use Pandorum while located within the United States any Prohibited Localities, as defined in the Terms of Use. </p>
            <p>3) I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory. </p>
            <p>4) I am lawfully permitted to access this site and use Pandorum under the laws of the jurisdiction on which I reside and am located. </p>
            <p>5) I understand the risks associated with entering into using Pandorum protocols. </p>
          </div>
          <button className='fullwidth grow btn' onClick={onSign}>sign</button>
        </div>
      </div>
    </div>
  )
}

const ConfirmationModal = ({ callNetSwitch, fromNet, toNet, token, amount, requestClose, recover }) => {
  const stepReducer = (state, action) => {
    switch (action) {
      case 'increment':
        return { ...state, step: state.step + 1 }
      case 'error':
        return { ...state, status: 'error' }
      case 'revert':
        return { step: 3, status: 'error' }
      case 'redeem':
        return { step: 3}
    }
  }

  const steps = ['', '', '', '']
  const [stepState, stepDispatch] = useReducer(stepReducer, { status: 'process', step: 0 })
  const { chainId } = useWeb3React()
  const { onDeposit, result } = useDeposit(recover)
  const { onRedeem, result: redeemResult } = useRedeem()

  const StepActions = ({ state }) => {
    switch (state.step) {
      case 0:
        if (state.status === 'error') {
          return (
            <>
              <p>Transaction failed!</p>
              <button onClick={requestClose} className="bottom fullwidth btn">close</button>
            </>
          )
        } else {
          return (
            <>
              <p>
                Awaiting transaction confirmation
              </p>
              <Spinner/>
              <p style={{textAlign: 'center'}}>
                Please don't refresh or close the page. We are waiting for transaction confirmations.
              </p>
            </>
          )
        }
      case 1:
        if (state.status === 'error') {
          return (
            <>
              <p>Error</p>
              {
                result.reverted_swap ?
                  <p>
                    Insufficient funds on the network
                  </p> : null
              }
              {
                result.rejected ?
                  <p>
                    Transaction rejected
                  </p> : null
              }
              <button onClick={requestClose} className="fullwidth btn">close</button>
            </>
          )
        } else {
          return (
            <>
              <p>
                Awaiting oracle confirmation
              </p>
              <Spinner/>
              <p>
                Current status: Waiting <br/>
              </p>
            </>
          )
        }
      case 2:
        if (!fromNet.name.includes('Zyx')) {
          return (
            <Spinner/>
          )
        }
        const nets = Object.values(Networks).find(n => n.ChainId === parseInt(result.chain_id_to))
        return (
          <>
            <p>Switch network!</p>
            <p>Awaiting chain switch to {nets.name} ({nets.ChainId})</p>
            <Spinner/>
            <button onClick={() => callNetSwitch(nets)} style={{ width: '100%' }}
                    className={'btn bottom'}>Switch network
            </button>
          </>
        )
      case 3:
        const netredeem = Object.values(Networks).find(n => n.ChainId === parseInt(result.chain_id_to))
        if (stepState.status === 'error') {
          return (
            <>
              <p>Transaction reverted! Insufficient liquidity on target network.</p>
              <p>Your tokens have been transferred back.</p>
              <button onClick={requestClose} style={{ width: '100%' }}
                      className={'btn bottom'}>Close
              </button>
            </>
          )
        } else if (!netredeem.name.includes('Zyx')) {
          return (
            <>
              <p>Success!</p>
              <p>You may now redeem your tokens</p>
              <button onClick={() => onRedeem(netredeem, result)} style={{ width: '100%' }}
                      className={'btn bottom'}>Claim
              </button>
              <button onClick={requestClose} style={{ marginTop: '15px', width: '100%' }}
                      className={'btn bottom'}>Close
              </button>
            </>
          )
        } else {
          const zyxNet = Networks['Zyx']
          return (
            <>
              <p>Success!</p>
              <p style={{marginBottom: '1em', textAlign: 'center'}}>Your tokens will be transferred by the oracle within 5 minutes. You may now switch to Zyx network.</p>
              <button onClick={() => callNetSwitch(zyxNet)} style={{ width: '100%' }}
                      className={'btn bottom'}>Switch Network
              </button>
              <button onClick={requestClose} style={{ marginTop: '15px', width: '100%' }}
                      className={'btn bottom'}>Close
              </button>
            </>
          )
        }
      default:
        return <p>error</p>
    }
  }

  // useEffect(() => {
  //   console.log(result)
  // }, [result])

  useEffect(() => {
    if (stepState.step === 2) {
      const nets = Object.values(Networks).find(n => n.ChainId === parseInt(result.chain_id_to))
      if (nets.name.includes('Zyx')) {
        stepDispatch('redeem')
      } else if (chainId === nets.ChainId) {
        stepDispatch('increment')
      }
    }
  }, [chainId, stepState.step, result])

  useEffect(() => {
    if (stepState.step === 0) {
      onDeposit(fromNet, toNet, token, amount)
    }
    // if (stepState.step === 2) {
    //   callNetSwitch(toNet)
    // }
  }, [stepState.step])

  useEffect(() => {
    if (redeemResult.status !== undefined) {
      requestClose()
    }
  }, [redeemResult])

  useEffect(() => {
      if (result === 'error') {
        stepDispatch('error')
      }
      if (stepState.step === 0 && result.status !== undefined) {
        stepDispatch('increment')
      }
      if (stepState.step === 1 && result.is_reverted) {
        stepDispatch('revert')
      } else if (stepState.step === 1 && result.chain_id_to) {
        stepDispatch('increment')
      }
    }
    ,
    [result]
  )

  return (
    <div className="modal-content" style={{ width: '375px', minHeight: '200px' }}>
      <div className="modal-title">
        <Steps progressDot status={stepState.status} size="small" current={stepState.step}>
          {steps.map((label, index) => {
            return (<Steps.Step title={label}/>)
          })}
        </Steps>
      </div>
      <div style={{ userSelect: 'text' }} className="result">
        <div className="centered">
          <StepActions dispatcher={stepDispatch} state={stepState}/>
        </div>
      </div>
    </div>
  )
}

const NetworkSelectModal = ({ selector }) => {
  const { chainId } = useWeb3React()
  return (
    <div className="modal-content">
      <div className="modal-title">
        <p className="modal__title">Select network</p>
      </div>
      <div>
        {
          Object.entries(Networks).map(([k, v]) =>
            <button style={{ width: '100%', marginBottom: '5px' }} onClick={() => {selector(v)}}
                    className="btn button">
              <p>{v.name}</p>
            </button>
          )
        }
      </div>
    </div>
  )
}

const Spinner = ({style}) =>
  <div style={style} className="lds-ellipsis grow">
    <div></div>
    <div></div>
    <div></div>
  </div>


const modalStyle = {
  content: {
    zIndex: 999,
    background: '#242628',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    borderColor: '#4a4a4a',
    minWidth: '220px',
    padding: '30px',
    userSelect: 'none',
    boxShadow: '0px 10px 13px -7px #151c23'
  },
  overlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
}
