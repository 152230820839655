import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { decimals, Networks, Tokens, ZYX_NODE } from '../constants'
import {Contract} from "@ethersproject/contracts";
import {formatUnits} from "@ethersproject/units";
import { fetcher } from './fetcher'
import useSWR from 'swr'
import depositABI from './depositABI.json'
import depositZyxABI from './depositZyxABI.json'
import { parseUnits } from 'ethers/lib/utils'
import axios from 'axios'

export const useTOS = () => {
  const { library, chainId, account } = useWeb3React()
  const [signed, setSigned] = useState(false)

  const handleSign = useCallback(
    async () => {
      const msg = "1) I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, as defined in the Terms of Use.\n2) I will not in the future access this site or use Pandorum while located within the United States any Prohibited Localities, as defined in the Terms of Use.\n3) I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.\n4) I am lawfully permitted to access this site and use Pandorum under the laws of the jurisdiction on which I reside and am located.\n5) I understand the risks associated with entering into using Pandorum protocols."
      const signer = library.getSigner()
      signer.signMessage(msg)
        .then(r => {
          axios.post(ZYX_NODE + '/sign', { address: account, sign: r })
            .then(() => setSigned(true))
            .catch(console.error)
        })
    },[library])

  useEffect(() => {
    if (library) {
      axios.get(ZYX_NODE + '/isSigned', { params: { address: account } })
        .then(r => {
          if (r.status === 200) {
            setSigned(true)
          }
        })
        .catch(console.error)
    }
  }, [account])
  
  return { onSign: handleSign, signed}
}
