import { useWeb3React } from '@web3-react/core'
import { formatUnits, parseEther } from '@ethersproject/units'
import { Tokens } from '../constants'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import ERC20ABI from './erc20abi.json'
import { Contract } from '@ethersproject/contracts'

export const useBalance = (net, tokenStr) => {
    const { account, library, chainId } = useWeb3React()
    const [balance, setBalance] = useState('0')
    const token = net.tokens[tokenStr]
    const decimals = token?.decimals ?? Tokens[tokenStr].decimals

    useEffect(() => {
        if (library && token !== undefined) {
            setBalance('0')
            //native tokens have zero address
            if ([...token.address].filter(x => x === '0').length === 41) {
                const eth = new ethers.providers.Web3Provider(library.provider)
                eth.getBalance(account).then(
                  b => {
                      const gasFee = parseEther('0.001')
                      const withFee = b.sub(gasFee) > 0 ? b.sub(gasFee) : parseEther('0')
                      setBalance(ethers.utils.formatEther(withFee.sub(withFee.mod(1e12))))
                  }
                )
            } else if (chainId === net.ChainId) {
                const contract = new Contract(token.address, ERC20ABI, library.getSigner())
                const modDecimals = decimals - 6
                contract.balanceOf(account).then(res => setBalance(formatUnits(res.sub(res.mod(Math.pow(10, modDecimals))), decimals)))
            }
        }
    }, [net, token, chainId, account])


    return balance
}