import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'
import { Tokens } from '../constants'
import { ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import ERC20ABI from './erc20abi.json'
import { formatUnits } from '@ethersproject/units'
import { parseEther } from '@ethersproject/units/lib.esm'

export const useAllowance = (net, tokenStr) => {
  const { account, library, chainId } = useWeb3React()
  const [allowance, setAllowance] = useState('0')
  const [txResult, setTxResult] = useState({})
  const token = net.tokens[tokenStr]
  const decimals = Tokens[tokenStr].decimals

  useEffect(() => {
    if (library && token !== undefined) {
      setAllowance('0')
      //native tokens have zero address
      if ([...token.address].filter(x => x === '0').length === 41) {
        setAllowance('-')
      } else if (chainId === net.ChainId) {
        const contract = new Contract(token.address, ERC20ABI, library.getSigner())
        contract.allowance(account, net.contract).then(res => setAllowance(formatUnits(res, decimals)))
      }
    }
  }, [net, token, chainId, account, txResult])

  const handleApprove = useCallback(
    async (net, token) => {
      try {
        const tokenAddr = net.tokens[token].address
        const contract = new Contract(tokenAddr, ERC20ABI, library.getSigner())

        contract.approve(net.contract, parseEther('9999999999').toString())
          .then(tx => {
            setTxResult('waiting')
            tx.wait(2).then(receipt => {
              setTxResult(receipt)
            })
          })
          .catch(e => {
            console.log(e)
            setTxResult('error')
          })
      } catch (e) {
        console.log(e)
        setTxResult('error')
        return e
      }
    },
    [library, account]
  )

  return { allowance, onApprove: handleApprove, result: txResult }
}
