import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { decimals, Networks, Tokens, ZYX_NODE } from '../constants'
import {Contract} from "@ethersproject/contracts";
import {formatUnits} from "@ethersproject/units";
import { fetcher } from './fetcher'
import useSWR from 'swr'
import depositABI from './depositABI.json'
import depositZyxABI from './depositZyxABI.json'
import { parseUnits } from 'ethers/lib/utils'
import axios from 'axios'

export const useRecover = () => {
  const { library } = useWeb3React()
  const [recoverData, setRecoverData] = useState({})

  const handleRecover = useCallback(
    async (hash) => {
      setRecoverData({recover: true, transactionHash: hash})
    },[library])

  return { onRecover: handleRecover, recoverData }
}
